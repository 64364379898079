import * as moment from 'moment';
import { Injectable } from "@angular/core";

@Injectable()
export class SharedUtils {

  constructor() {
  }

  /****************************************************************************************************/

  // TOOLS
  static isDate(d) {
    return (d instanceof Date);
  }

  static isObject(o) {
    return (o != null && typeof o === 'object');
  }

  static isEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  static isNumber(val) {
    return typeof val === 'number';
  }

  static getURLWithoutParams() {
    return window.location.pathname;
  }

  static getGlobalMenuType() {
    return window.location.pathname.split('/')[1];
  }

  static generateRandomString(charCount: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < charCount) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  /****************************************************************************************************/

  // HTML
  static getHTMLElementById(id: string): HTMLElement {
    let element: HTMLElement = document.getElementById(id) as HTMLElement;
    return element;
  }

  static removeHTMLEntitiesFromBase64(base64String: string): string {
    return base64String.split(',')[1];
  }

  static setAttributes(el: HTMLElement, attrs: {[key: string]: string}): void {
    for (var key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
  }

  /****************************************************************************************************/

  // ARRAYS
  static removeFromArray(array: any[], element: any): void {
    let index = array.indexOf(element);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  static getObjFromArrayByAttr(arr: any[], attr: string, val: any): any {
    let foundObj: any = null,
      i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && arr[i][attr] === val) {
        foundObj = arr[i];
        break;
      }
    }

    return foundObj;
  }

  static getEntityTitleById(array: any[], nameKey: string, id: number): string {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        return array[i][nameKey];
      }
    }
    return '';
  }


  /****************************************************************************************************/

  // OBJECTS
  /**
   * Deletes each empty property (before create request)
   * @param obj
   * @param {any[]} meanPropsOfNull
   * @returns {any}
   */
  static deleteObjEmptyProps(obj: object, meanPropsOfNull: string[] = []): any {
    let propNames = Object.getOwnPropertyNames(obj);
    for (let i = 0; i < propNames.length; i++) {
      let propName = propNames[i];
      if (meanPropsOfNull.indexOf(propName) !== -1) continue;
      if (obj[propName] === null) {
        delete obj[propName];
        continue;
      }
      switch (typeof obj[propName]) {
        case 'string':
          if (obj[propName] === '') {
            delete obj[propName];
          }
          break;
        case 'number':
          if (obj[propName] === 0) {
            delete obj[propName];
          }
          break;
      }
    }
    return obj;
  }

  static clone(obj) {
    // in case of premitives
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    // date objects should be
    if (obj instanceof Date) {
      return new Date(obj.getTime());
    }

    // handle Array
    if (Array.isArray(obj)) {
      let clonedArr = [];
      obj.forEach(element => {
        clonedArr.push(this.clone(element));
      });
      return clonedArr;
    }

    // lastly, handle objects
    let clonedObj = new obj.constructor();
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        clonedObj[prop] = this.clone(obj[prop]);
      }
    }
    return clonedObj;
  }

  // flattens an object
  static flattenObjValues = (obj, cache = {}, exceptionList?) => {
    const objectValues = Object.keys(obj).reduce((acc, cur) => {
        if (!Array.isArray(obj[cur]) && typeof obj[cur] === 'object' && obj[cur] !== null && !exceptionList?.includes(cur)) {
            return SharedUtils.flattenObjValues({ ...acc, ...obj[cur] }, cache);
        }
        acc[cur] = obj[cur];
        return acc;
    }, {});
    return {
        ...objectValues,
        ...cache,
    };
  }

  // Delete field from object
  static recursiveObjectFieldRemover(obj: any, fieldName: string): void {
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        this.recursiveObjectFieldRemover(obj[key], fieldName);
      } else if (key === fieldName){
        delete obj[key];
      }
    }
  }

  // Works when you have simple JSON-style objects without methods and DOM nodes inside
  static fastObjectComparison(obj1: Object, obj2: Object): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  /**
  * Inverts an object's keys and values.
  * @param obj - The object to flip
  * @throws {Error} If input is null/undefined or contains non-string values
  * @returns A new object with keys and values swapped
  */
  static flipObject<T extends Record<string, string>>(obj: T): Record<string, string> {
    if (!obj) {
      throw new Error('Input object cannot be null or undefined');
    }

    const invertedObj = {};
    Object.keys(obj).forEach(key => {
      invertedObj[obj[key]] = key;
    });
    return invertedObj;
  }

  // Convert size in bytes
  static formatBytes(bytes: number, decimals?: number, needMeasure: boolean = true): string | number {
    if (bytes === 0) return 0;
    let k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

    const parsedNumber: number = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
    return (needMeasure) ? `${parsedNumber} ${sizes[i]}` : parsedNumber;
  }

  static get OSName(): string | undefined {
    let OSName: string | undefined = undefined;
    if (window.navigator.appVersion.indexOf('Win') !== -1) OSName = 'Windows';
    if (window.navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS';
    if (window.navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX';
    if (window.navigator.appVersion.indexOf('Linux') !== -1) OSName = 'Linux';
    return OSName;
  }

  static isInt(n): any {
    return Number(n) === n && n % 1 === 0;
  }

  static isFloat(n): any {
    return Number(n) === n && n % 1 !== 0;
  }

  static isUpperCase(str): boolean {
    return str !== str.toLowerCase() &&
           str === str.toUpperCase();
  }

  static getParsedDate(ts: number, format: string): string {
    return (ts.toString().length === 10) ? moment.unix(ts).format(format) : moment(ts).format(format);
  }

  static copyInputMessage(inputElement: HTMLInputElement): void {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  static copyMessage(val: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  static entityCompare(a, b): any {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  }

  static domainValid(domain: string): boolean {
    return /^([a-zA-Z0-9-]{1,61}\.)*[a-zA-Z0-9-]{1,61}\.[a-zA-Z]{2,}$/.test(domain);
  }

  static subDomainValid(domain: string): boolean {
    return /^[A-Za-z0-9]+([-A-Za-z0-9])*$/.test(domain);
  }

  static convertBase64ToByteArray(base64: string) {
    let binary_string =  window.atob(base64.split(',')[1]);
    let len = binary_string.length;
    let bytes = new Uint8Array( len );
    for (let i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  static getTimeFromNow(timestamp: number, lang: string = 'en'): string {
    return moment(timestamp).locale(lang).fromNow();
  }

  static getDaysAndHoursBetweenTwoTimestamps(startTS: number, endTS: number): Record<string, number> {
    const start = moment(startTS);
    const end = moment(endTS);
    const duration = moment.duration(end.diff(start));
    return {days: Math.floor(duration.asDays()), hours: Math.floor(duration.hours())};
  }

  static getDaysAndHoursAndMinutesBetweenTwoTimestamps(startTS: number, endTS: number): Record<string, number> {
    const start = moment(startTS);
    const end = moment(endTS);
    const duration = moment.duration(end.diff(start));
    return {days: Math.floor(duration.asDays()), hours: Math.floor(duration.hours()), minutes: Math.floor(duration.minutes())};
  }

  static getScrollbarWidth(): number {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }

  static observerForElementInDom(selector: string): Promise<Element> {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
  }

  /**
   * Checks if a given file name contains emojis.
   * @param {string} fileName - The file name to check.
   * @returns {boolean} True if emojis are found, false otherwise.
   */
  static containsEmojis(fileName: string): boolean {
    // Regular expression to match emojis
    const emojiRegex =
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;

    return emojiRegex.test(fileName);
  }

}
