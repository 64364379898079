import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserService } from './user/user.service';
import { iActivityLog, iComment, iCommunityLog, iCommunityRequestParams } from '@coreinterfaces/community';
import { HttpRequestService } from './http-request.service';
import { iPortalMember } from '@coreinterfaces/user';


@Injectable()
export class CommunityService {

  isLoadingTopic = false;
  isLoadingCommunity = false;

  updateFeedView = new BehaviorSubject({title: '', event: '', id: 0 as any});

  _deleteEntity: Subject<any> = new Subject<any>();
  $deleteEntity: Observable<any> = this._deleteEntity.asObservable();

  _moveEntity: Subject<any> = new Subject<any>();
  $moveEntity: Observable<any> = this._moveEntity.asObservable();

  constructor(private _userService: UserService,
              private _httpService: HttpRequestService) {}
  /**
   * Custom post request
   * @param reqURL
   * @param bodyString
   * @param requestOptions
   * @returns {any}
   */

  get(url: string): Observable<any> {
    return this._httpService.communityApiGet(url);
  }

  getWithToken(url: string, workerIgnore: boolean = false): Observable<any> {
    let reqUrl = url + '?token=' + this._userService.token;
    if(workerIgnore) {
      reqUrl+= '&ngsw-bypass=true'
    }
    return this._httpService.communityApiGet(reqUrl);
  }

  set(endPoint: string, requestOptions?: any): Observable<any> {
    let requestTokenParam = {token: this._userService.token, ...requestOptions};
    const bodyString = JSON.stringify(requestTokenParam);
    return this._httpService.communityApiPost(endPoint, bodyString);
  }

  post(endPoint: string, requestParams: iCommunityRequestParams): Observable<any> {
    let requestTokenParam = {token: this._userService.token};
    requestParams = {...requestParams, ...requestTokenParam}
    const bodyString = JSON.stringify(requestParams);
    return this._httpService.communityApiPost(endPoint, bodyString);
  }

  postV2(endPoint: string, requestParams: iCommunityRequestParams): Observable<iComment[]> {
    let requestTokenParam = {token: this._userService.token};
    requestParams = {...requestParams, ...requestTokenParam}
    const bodyString = JSON.stringify(requestParams);
    return this._httpService.communityApiPostV2(endPoint, bodyString);
  }

  /*****************************************************  GETTERS  ****************************************************/

  getInfo(entityType: string, id: number): Observable<any> {
    let requestParams: iCommunityRequestParams = { id };
    return this.post(`${entityType}/info`, requestParams);
  }

  getMyPosts(entityType: string,  requestParams?: iCommunityRequestParams): Observable<any> {
    return this.post(`${entityType}/my/get`, requestParams);
  }

  getRefCode(entityType: string, id: number): Observable<any> {
    let requestParams: iCommunityRequestParams = { community_id: id };
    return this.post(`${entityType}/get`, requestParams);
  }

  getList(entityType: string, requestParams?: iCommunityRequestParams): Observable<any> {
    return this.post(`${entityType}/list`, requestParams);
  }

  getCommentList(entityType: string, requestParams?: iCommunityRequestParams): Observable<iComment[]> {
    return this.postV2(`${entityType}/list`, requestParams);
  }

  getRange(entityType: string, requestParams?: iCommunityRequestParams): Observable<any> {
    return this.post(`${entityType}/range`, requestParams);
  }

  getCount(entityType: string, requestParams?: iCommunityRequestParams): Observable<any> {
    return this.post(`${entityType}/count`, requestParams);
  }

  getLikedUserList(entityType: string, requestParams?: iCommunityRequestParams): Observable<any> {
    return this.post(`${entityType}/like/get`, requestParams);
  }

  getTopicActivityList(requestParams?: iCommunityRequestParams): Observable<any> {
    return this.post('topic/activity/list', requestParams);
  }

  getAccessTypeList(requestParams?: iCommunityRequestParams): Observable<any[]> {
    return this.post('community/access_types', requestParams)
  }

  /*****************************************************  ACTIONS  ****************************************************/

  add(entityType: string, entity: any, requestData?: iCommunityRequestParams): Observable<any> {
    let requestParams: iCommunityRequestParams = { values: entity, ...requestData };
    return this.post(`${entityType}/add`, requestParams);
  }

  postNow(entityId: number): Observable<any> {
    let requestParams: iCommunityRequestParams = { id: entityId };
    return this.post(`/thread/publish`, requestParams);
  }

  report(entityType: string, entity: any): Observable<any> {
    let requestParams: iCommunityRequestParams = { token: this._userService.token, ...entity };

    return this.post(`${entityType}/report`, requestParams);
  }

  update(entityType: string, entity: any, id: number): Observable<any> {
    let requestParams: iCommunityRequestParams = {id: id, values: entity };
    return this.post(`${entityType}/update`, requestParams);
  }

  updateEntity(entityType: string, entity: any, requestOptions?: any): Observable<any> {
    const reqUrl = `${entityType}/update`;
    let requestParams: iCommunityRequestParams = { token: this._userService.token, values_to_update: entity };
    return this.post(reqUrl, requestParams);
  }

  delete(entityType: string, id: number): Observable<any> {
    let requestParams: iCommunityRequestParams = { id };
    return this.post(`${entityType}/delete`, requestParams);
  }

  archiveCommunity(id: number): Observable<any> {
    let requestParams: iCommunityRequestParams = { id };
    return this.post('community/archive', requestParams);
  }

  getUserLog(entity: any): Observable<iPortalMember["action_logs"]> {
    let requestParams: iCommunityRequestParams = { token: this._userService.token, ...entity };
    return this.post('user_log/list', requestParams);
  }

  getUserLogCount(id: number): Observable<number> {
    let requestParams: iCommunityRequestParams = { token: this._userService.token, customer_id: id };
    return this.post('user_log/count', requestParams);
  }
  
  getThreadList(ids: number[]): Observable<iCommunityLog[]> {
    let requestParams: iCommunityRequestParams = { token: this._userService.token, ids };
    return this.post('thread/get', requestParams);
  }

  getTopicList(ids: number[]): Observable<iCommunityLog[]> {
    let requestParams: iCommunityRequestParams = { token: this._userService.token, ids };
    return this.post('topic/get', requestParams);
  }

  getThreadMessageList(ids: number[]): Observable<iCommunityLog[]> {
    let requestParams: iCommunityRequestParams = { token: this._userService.token, ids };
    return this.post('thread_message/log/get', requestParams);
  }

  unarchiveCommunity(id: number): Observable<any> {
    let requestParams: iCommunityRequestParams = { id };
    return this.post('community/unarchive', requestParams);
  }

  like(entityType: string, id: number, is_liked: boolean): Observable<any> {
    const endPoint = entityType +  '/like/' + ((is_liked) ? 'delete' : 'add');
    let requestParams: iCommunityRequestParams = { id };
    return this.post(endPoint, requestParams);
  }

  deleteEntity(entityObj: {type: string, id: number, parentId: number}): void {
    this._deleteEntity.next(entityObj);
  }

  moveEntity(entityObj: {type: string, parentId: number}): void {
    this._moveEntity.next(entityObj);
  }

  moveThread(entity: any): Observable<any> {
    const { thread_id, topic_id } = entity;
    let requestParams: iCommunityRequestParams = { thread_id, topic_id };
    return this.post('thread/move', requestParams);
  }
}
