import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { iComment } from '@coreinterfaces/community';

type GetRequestOptionsParameter = Parameters<HttpClient['get']>[1];
type PostBodyParameter = Parameters<HttpClient['post']>[1];
type PostRequestOptionsParameter = Parameters<HttpClient['post']>[2];

@Injectable()
export class HttpRequestService {
  defaultHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private _http: HttpClient) {}

  post<Response>(reqURL: string, body: PostBodyParameter = {}, requestOptions?: PostRequestOptionsParameter): Observable<Response> {
    return this._http.post<{ done: boolean, result: Response, error?: string }>(
      reqURL,
      body,
      requestOptions || this.defaultHttpOptions
    ).pipe(
      map((response: any) => {
        if (response.done) {
          return response.result;
        } else {
          throw new Error(response.error);
        }
      })
    );
  }

  get<Response>(reqURL: string, options?: GetRequestOptionsParameter): Observable<Response> {
    return this._http.get<{ done: boolean, result: Response, error?: string }>(reqURL, options).pipe(
      map((response) => {
        if (response.done) {
          return response.result;
        } else {
          throw new Error(response.error);
        }
      })
    );
  }

  fullGet(reqURL: string, requestOptions?: any): Observable<any> {
    return this._http.get(reqURL, requestOptions);
  }

  /*************************************************  ***********************************************/

  entityApiPost(reqURL: string, bodyString: string, requestOptions?: any): Observable<any> {
    return this.post(`${environment.apiUrl}/${reqURL}`, bodyString, requestOptions || this.defaultHttpOptions);
  }

  entityApiGet(reqURL: string): Observable<any> {
    return this.get(`${environment.apiUrl}/${reqURL}`);
  }

  exportApiPost(reqURL: string, bodyString: string, requestOptions?: any): Observable<any> {
    return this.post(`${environment.exportApiUrl}/${reqURL}`, bodyString, requestOptions || this.defaultHttpOptions);
  }

  exportApiGet(reqURL: string): Observable<any> {
    return this.get(`${environment.exportApiUrl}/${reqURL}`);
  }

  exportApiFullGet(reqURL: string, requestOptions?: any): Observable<any> {
    return this.fullGet(`${environment.exportApiUrl}/${reqURL}`, requestOptions);
  }

  communityApiPost(reqURL: string, bodyString: string, requestOptions?: any): Observable<any> {
    return this.post(`${environment.communityApi.url}/${environment.communityApi.version}/${reqURL}`, bodyString, requestOptions)
  }

  communityApiPostV2(reqURL: string, bodyString: string, requestOptions?: any): Observable<iComment[]> {
    return this.post(`${environment.communityApiV2.url}/${environment.communityApiV2.version}/${reqURL}`, bodyString, requestOptions)
  }

  communityApiGet(reqURL: string): Observable<any> {
    return this.get(`${environment.communityApi.url}/${environment.communityApi.version}/${reqURL}`);
  }

  notificationsApiPost(reqURL: string, bodyString: string, requestOptions?: any): Observable<any> {
    return this.post(`${environment.notificationApi.url}/${environment.notificationApi.version}/${reqURL}`, bodyString, requestOptions)
  }

  notificationsApiGet(reqURL: string, requestOptions?: any): Observable<any> {
    return this.get(`${environment.notificationApi.url}/${environment.notificationApi.version}/${reqURL}`);
  }

  integrationApiPost(reqURL: string, bodyString: string, requestOptions?: any): Observable<any> {
    return this.post(`${environment.integrationApi.url}/${environment.integrationApi.version}/${reqURL}`, bodyString, requestOptions)
  }

  openAIApiPost(reqURL: string, bodyString: string, requestOptions?: any): Observable<any> {
    return this.post(`${environment.openAIApi.url}/${environment.openAIApi.version}/${reqURL}`, bodyString, requestOptions);
  }

  senderApiPost(reqURL: string, bodyString: string, requestOptions?: any): Observable<any> {
    return this.post(`${environment.senderApi.url}/${environment.senderApi.version}/${reqURL}`, bodyString, requestOptions)
  }

  authApiPost(reqURL: string, bodyString: string, requestOptions?: any): Observable<any> {
    return this.post(`${environment.authApi.url}/${reqURL}`, bodyString, requestOptions)
  }

  landingApiPost(reqURL: string, bodyString: string, requestOptions?: any): Observable<any>
  {
    return this.post(`${environment.landingApi.url}/${environment.landingApi.version}/${reqURL}`,bodyString,requestOptions)
  }

  importApiPost(reqURL: string, bodyString: string, requestOptions?: any): Observable<any>
  {
    return this.post(`${environment.importApiUrl}/${reqURL}`,bodyString,requestOptions)
  }
}
