import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "../services/user/user.service";

@Injectable()
export class CommunityLinkGuard implements CanActivate {
  constructor(private _router: Router, private _userService: UserService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentUrl = window.location.href;
    const hasTopicOrThread = currentUrl.includes("topic") || currentUrl.includes("thread");
    const hasAdminOrCustomer = currentUrl.includes("admin") || currentUrl.includes("customer") || currentUrl.includes("customer-new");
  
    if (hasTopicOrThread && hasAdminOrCustomer) {
      localStorage.setItem("communityUrl", state.url);
    }
  
    return true;
  }
}
