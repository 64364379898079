import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EntityService } from './entity.service';
import { UserService } from './user/user.service';
import { HttpRequestService } from './http-request.service';
import { iCustomTheme, iTheme } from '@coreinterfaces/theme-new';
import { iThemeStyles } from '@coreinterfaces/theme-new';

@Injectable({
  providedIn: 'root'
})
export class NewDesignSettingsService {

    private _changeNewDesignState = new Subject<boolean>();
    changeNewDesignState$ = this._changeNewDesignState.asObservable();

    private _activeTheme: iTheme;
    private _themesCodeMap: Map<number, string> = new Map();

    constructor(private _entityService: EntityService, private _userService: UserService, private _httpService: HttpRequestService) { }

    changeNewDesignState(state: boolean) {
      this._changeNewDesignState.next(state);
    }

    setThemeParameters(properties: iThemeStyles) {
      Object.keys(properties).forEach(key => {
          document.documentElement.style.setProperty('--'+key, properties[key]);
      })
    };

    setActiveTheme(theme): void {
      this._activeTheme = theme;
    }

    getActiveTheme(): any {
      return this._activeTheme;
    }

    setThemesCodeMap(themesCodeArray: any): void {
      themesCodeArray.forEach(themeCode => {
        this._themesCodeMap.set(themeCode.id, themeCode.title)
      })
    }

    getThemesCodeMap(): any {
      return this._themesCodeMap;
    }

    /**
     * Custom post request
     * @param reqURL
     * @param bodyString
     * @param requestOptions
     * @returns {any}
     */

    post(endPoint: string, requestParams: any): Observable<any> {
      const requestTokenParam = {token: this._userService.token};
      requestParams = {...requestParams, ...requestTokenParam}
      const bodyString = JSON.stringify(requestParams);
      return this._httpService.entityApiPost(endPoint, bodyString);
    }

    /*****************************************************  GETTERS  ****************************************************/

    getInfo(entityType: string, optionalRequestParams?: any): Observable<any[]> {
      let requestParams;
      if(optionalRequestParams) {
        requestParams = optionalRequestParams;
      } else {
        let portal_id;
        if(this._userService.client?.portal.id) {
          portal_id = this._userService.client.portal.id;
        } else {
          portal_id = this._userService.portal?.id;
        }
        requestParams = { filter_by: [{ attribute: "portal_id", value: portal_id}] };
      }
      return this.post(`entity/${entityType}/info`, requestParams);
    }

    getInfoByPortalId(entityType: string, portalId: number): Observable<any[]> {
      const requestParams = { filter_by: [{ attribute: "portal_id", value: portalId }] };

      return this.post(`entity/${entityType}/info`, requestParams);
    }

    getList(entityType: string, requestParams?: any): Observable<any[]> {
      return this.post(`entity/${entityType}/list`, requestParams);
    }

    /*****************************************************  ACTIONS  ****************************************************/

    update(entityType: string, entity: any, id: number): Observable<any> {
      const requestParams: any = {values: {...entity, id: id} };
      return this.post(`entity/${entityType}/update`, requestParams);
    }

    updateContentBlock(entityType: string, entity: any, portal_id: number): Observable<any> {
      const requestParams: any = {values: entity, portal_id };
      return this.post(`entity/${entityType}/update`, requestParams);
    }

    activeContentBlock(entityType: string, portal_id: number, content_block_id: number): Observable<any> {
      const requestParams: any = { content_block_id, portal_id };
      return this.post(`${entityType}/activate`, requestParams);
    }

    activateTheme(themeId: number): Observable<any> {
      let portal_id;
      if(this._userService.client?.portal.id) {
        portal_id = this._userService.client.portal.id;
      } else {
        portal_id = this._userService.portal.id;
      }
      const requestParams: any = { portal_id: portal_id, design_id: themeId };
      return this.post('portal/design/activate', requestParams);
    }

}
